import { Timestamp } from "./timestamp";

export class AppUser {
	email: string = "";
	role: string = "client";
	username: string = "";
	firstName: string = "";
	lastName: string = "";
	uid: string = "";
	profilePicture: string = "";
	profilePictureUid: string = "";
	profilePictureThumb: string = "";
	profilePictureThumb1: string = "";
	dateCreated: Timestamp = new Timestamp(new Date().getTime() / 1000, 0);
	dateModified: Timestamp = new Timestamp(new Date().getTime() / 1000, 0);
	emailVerified: Timestamp = new Timestamp(new Date().getTime() / 1000, 0);
	active: boolean = false;
	activeSubscription: boolean = false;
	currentTimeZone: string = "";
	currentTimeZoneOffset: number = 0;
	agreedToTerms: boolean = false;
	lastUsedTrackId: string = "";
	sponsoredTrackId: string = "";
	bike: string = "";
	riderNumber: string = "";
	private epcCodes: string[] = []; //limit 10 for reverse search

	constructor() {}

	buildUser(user: AppUser) {
		for (var key in user) {
			if (user.hasOwnProperty(key)) {
				switch (key) {
					default:
						if (user[key] && user[key].getTime) {
							this[key] = new Timestamp(user[key].getTime() / 1000, 0);
						} else {
							this[key] = user[key] != undefined ? user[key] : null;
						}
						break;
				}
			}
		}
		return this;
	}

	getEpcCodes(): string[] {
		return this.epcCodes;
	}

	addEpc(epc: string) {
		if (this.epcCodes.length === 10) {
			this.epcCodes.splice(0, 1);
		}
		this.epcCodes.push(epc);
	}

	removeEpcCode(index: number) {
		this.epcCodes.splice(index, 1);
	}

	toObj() {
		return {
			email: this.email,
			role: this.role,
			username: this.username,
			firstName: this.firstName,
			lastName: this.lastName,
			uid: this.uid,
			profilePicture: this.profilePicture,
			profilePictureUid: this.profilePictureUid,
			profilePictureThumb: this.profilePictureThumb,
			profilePictureThumb1: this.profilePictureThumb1,
			dateCreated:
				this.dateCreated != null &&
				typeof this.dateCreated.toDate !== "undefined"
					? this.dateCreated.toDate()
					: this.dateCreated,
			dateModified:
				this.dateModified != null &&
				typeof this.dateModified.toDate !== "undefined"
					? this.dateModified.toDate()
					: this.dateModified,
			emailVerified:
				this.emailVerified != null &&
				typeof this.emailVerified.toDate !== "undefined"
					? this.emailVerified.toDate()
					: this.emailVerified,
			active: this.active,
			activeSubscription: this.activeSubscription,
			agreedToTerms: this.agreedToTerms,
			lastUsedTrackId: this.lastUsedTrackId,
			sponsoredTrackId: this.sponsoredTrackId,
			bike: this.bike,
			riderNumber: this.riderNumber,
			epcCodes: this.epcCodes,
		};
	}
}
