import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'eula-agreement',
  templateUrl: './eula-agreement.component.html',
  styleUrls: ['./eula-agreement.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule],
})
export class EulaAgreementComponent {
  constructor(public userService: UserService) {}
}
