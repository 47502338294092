<ion-header mode="md" [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start"> </ion-buttons>
    <ion-title class="ion-text-center">EULA Agreement</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-card>
          <ion-card-header>
            <ion-card-title>
              Please Review and Accept Our Terms
            </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <p>
              To ensure compliance and protect your use of our services, we
              require all users to accept the End User License Agreement (EULA).
            </p>
          </ion-card-content>
        </ion-card>

        <eula-agreement></eula-agreement>

        <ion-card>
          <ion-grid>
            <ion-row>
              <ion-col>
                <div>
                  <ion-button
                    fill="outline"
                    expand="block"
                    (click)="dismissWithCancel()"
                  >
                    I Disagree
                  </ion-button>
                </div>
              </ion-col>
              <ion-col>
                <ion-button expand="block" (click)="dismissWithConfirm()">
                  I Agree
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
