import { Component } from '@angular/core';
import { ModalController, IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { EulaAgreementComponent } from '../../components/eula-agreement/eula-agreement.component';

@Component({
  selector: 'page-eula-agreement-modal',
  templateUrl: 'eula-agreement-modal.html',
  styleUrls: ['eula-agreement-modal.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, EulaAgreementComponent],
})
export class EulaAgreementModal {
  constructor(public modalCtrl: ModalController) {}

  dismissWithConfirm() {
    this.modalCtrl.dismiss({ accepted: true }, 'confirm');
  }

  dismissWithCancel() {
    this.modalCtrl.dismiss(null, 'cancel');
  }
}
