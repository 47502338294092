import { Injectable } from "@angular/core";
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router,
	Route,
} from "@angular/router";
import { Observable } from "rxjs";
import { UserService } from "../core/services/user.service";

@Injectable({
	providedIn: "root",
})
export class ActiveSubAuthGuardService implements CanActivate {
	constructor(public _authService: UserService, public _router: Router) {}

	async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		this._authService.requestedRoute = window.location.pathname;
		if (
			(await this._authService.isUserSubscriptionActive()) ||
			this._authService.loggedInUser.role === "admin"
		) {
			console.log("ACTIVE SUBSCRIPTION AUTH");

			if (
				this.checkUnlocks(next.routeConfig.path) ||
				this._authService.loggedInUser.role === "admin"
			) {
				this._authService.refreshRequestedRoute = window.location.pathname;
				return true;
			}
		}

		console.log("ACTIVE SUBSCRIPTION NOT FOUND IN USER");
		this._router.navigate(["/"]);

		return false;
	}

	private checkUnlocks(path: string) {
		switch (path) {
			case "lap-times":
				return this._authService.isLapsUnlocked();

			case "live-stream":
				return this._authService.isLiveStreamUnlocked();

			case "video-clips":
				return this._authService.isVideoClipsUnlocked();

			default:
				return false;
		}
	}
}
